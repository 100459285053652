<template>
  <div class="container">
    <div class="header">
      <img src="../assets/contact/tt2.png" alt="" />
      <div class="center">
        <img src="../assets/contact/gsjj.png" alt="" />
      </div>
      <div class="right">
        <img src="../assets/contact/kf.png" alt="" />
        <!-- <el-popover
                    v-for="(item,i) in contacts"
                    :key="i"
                    placement="left"
                    :title=item.title
                    width="200"
                    trigger="hover"
                    :content=item.content>
                    <img slot="reference" :src="item.img" alt="">
               
                </el-popover> -->
        <!-- <div>
          <img slot="reference" src="../assets/contact/cxkh.png" alt="" />
        </div> -->
        <el-popover placement="left" width="150" trigger="hover">
          <p style="color: #127bf2">18758021092</p>
          <p>客服热线</p>
          <img slot="reference" src="../assets/contact/lxwm.png" alt="" />
        </el-popover>
        <el-popover placement="left" width="200" trigger="hover">
          <div class="codeflex" style="display: flex">
            <div class="codeleft">
              <img
                style="width: 100%"
                src="../assets/contact/qrcode.jpg"
                alt=""
              />
              <p style="color: #333333">官方微信服务号</p>
            </div>
            <div class="codeleft">
              <img
                style="width: 100%"
                src="../assets/contact/qrcode.jpg"
                alt=""
              />
              <p style="color: #333333">官方微信视频号</p>
            </div>
          </div>
          <img slot="reference" src="../assets/contact/WX.png" alt="" />
        </el-popover>
        <el-popover placement="left" width="200" trigger="hover">
          <p style="color: #127bf2">362995278@qq.com</p>
          <p>客服邮箱</p>
          <img slot="reference" src="../assets/contact/YX.png" alt="" />
        </el-popover>
        <div>
          <img
            @click="scrollToTop()"
            slot="reference"
            src="../assets/contact/ZD.png"
            alt=""
          />
        </div>
      </div>
      <div class="bottom">
        <div v-for="(item, i) in profile" :key="i">
          <div class="data">
            <span class="num">{{ item.num }}</span>
            <span class="unit">{{ item.unit }}</span>
          </div>
          <div class="desc">{{ item.desc }}</div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">公司介绍</div>
      <div v-html="intro1" class="description"></div>
      <div v-html="intro2" class="description"></div>
      <div v-html="intro3" class="description"></div>
      <div class="contact">
        <img src="../assets/contact/qrcode.jpg" alt="" />
        <div>关注微信公众号</div>
      </div>
      <div class="bg">
        <img src="../assets/contact/GST.png" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      profile: [
        {
          num: 1986,
          unit: "年",
          desc: "成立年份",
        },
        {
          num: 10,
          unit: "个",
          desc: "业务范围",
        },
        {
          num: 150,
          unit: "名",
          desc: "公司员工",
        },
        {
          num: 160,
          unit: "万元",
          desc: "2023年年收入",
        },
      ],
      intro1: `浙江白象电器成套设备有限公司(原杭州白象建筑电器厂)成立于一九八六年，原隶属浙江省三建建设集团有限公司，经多次企业股份制改制，
      现拥有员工150余人，其中高级职称3人、中级职称10人、专业技术人员20人。公司在德清乾元镇有浙江白象电器产业基地，有生产车间、办公楼、
      员工宿舍、食堂等建筑20000㎡，具备大规模生产高、低压成套设备的能力。一体化智能配电，精密化控制柜的能力。  `,
      intro2: `      公司技术力量雄厚，生产检测设备先进。产品曾获得《浙江省产品质量检查合格证》、机电部《全国统检合格证书》、1
      996年机电部《XL-21型动力配电箱抽样检测合格证书》、1997年机械工业部GGD型低压成套开关柜《全国质量统检合格证》、
      1997年GDH低压交流配电柜》、2004年《浙江省质量服务信得过单位》、2003杭州市首批《信用优质企业》、1994-2005杭州市
      《守合同重信用》单位、2018-2019企业信用评价为“AAA”。我公司自行设计制造的GDH安全型配电柜获国家2013年实用新
      型专利（专利号：ZL201220625551.3）、IP65-20C系列消防巡检柜获“CCCF”国家强制性消防认证、YB-12/0.4-1250预装
      式变电站，KYN28A-12/1250-31.5铠装移动式交流金属封闭开关设备双双获得国家电器安全质检中心合格《检测报告》。`,
      intro3: `公司一贯秉承“质量至上、用户满意”的企业宗旨，把顾客的利益放在首位，严格按照GB/T19001-2016/ISO9001:2015质量管理体系标准、
      OHSAS18001：2007《职业健康安全管理体系》、国家“三C ”《强制性产品认证》组织生产，产品被国家经济贸易委员会推荐为全国城乡电
      网建设与改造所需主要设备产品及生产企业。公司产品广泛应用于工业与民用电力控制系统，如宾馆饭店、机场、高层建筑、商业中心、
      住宅小区等重要场所，以及地铁、冶炼、海岛等环境恶劣场所，深受市场好评！
      公司以可靠的质量，优惠的价格，完善的服务期待与每一家客商真诚合作，携手并进创造更加辉煌的明天。`,
      contacts: [
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/cxkh.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/lxwm.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/WX.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/YX.png"),
        },
        {
          title: "标题",
          content: "这是一段内容,这是一段内容,这是一段内容,这是一段内容。",
          img: require("../assets/contact/ZD.png"),
        },
      ],
    };
  },
  methods: {
    // 滚动到顶部
    scrollToTop() {
      // 滚动到页面顶部
      window.scrollTo({
        top: 0,
        behavior: "smooth", // 平滑滚动
      });
    },
  },
};
</script>

<style scoped>
.header {
  position: relative;
}

.header > img {
  width: 100%;
  /* height: 80vh; */
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center img {
  width: 4.2rem;
  height: 2.8rem;
}

.right {
  position: fixed;
  right: 0.5%;
  top: 40%;
  transform: translate(0, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 999;
}
.right img {
  width: 0.68rem;
  height: 0.68rem;
}
.right > img {
  width: 0.9rem;
  height: 0.9rem;
}

.bottom {
  width: 100%;
  height: 2rem;
  background: rgba(1, 21, 55, 0.6);
  position: absolute;
  bottom: 0;
  box-sizing: border-box;
  padding: 0 2rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: white;
}
.num {
  font-size: 0.58rem;
  font-family: Arial-Black, Arial;
  font-weight: 900;
  margin-right: 0.1rem;
}
.desc,
.unit {
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  text-align: center;
}

.content {
  padding-left: 2.36rem;
  position: relative;
}

.title {
  font-size: 0.48rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #0a0a0a;
  padding: 0.47rem 0 0.39rem 0;
}

.description {
  width: 9.18rem;
  /* height: 2.84rem; */
  font-size: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #101010;
  line-height: 0.5rem;
  margin-bottom: 0.6rem;
  text-indent: 2em;
}

.contact img {
  width: 2.24rem;
}
.contact div {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #101010;
  letter-spacing: 4px;
  padding-bottom: 0.7rem;
}

.bg {
  width: 9.8rem;
  height: 6.86rem;
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
}
.bg img {
  width: 100%;
  height: 100%;
}
</style>
